const toggleFlag = (flagName, projectId, buildJobId) => {
    const groupSlug = $('#js_projectIds').data('group-slug');

    const url  = `/${groupSlug}/projects/${projectId}/build_jobs/${buildJobId}/flag`;

    // $.post is not sufficient, since Rails needs to know the dataType.
    // This is necessary to process JS correctly, avoiding a request "processing as */*"
    // instead of a correct "processing as JS".
    return $.ajax({
        type: 'POST',
        url: url,
        data: { flag_name: flagName },
        dataType: 'script'
    });
};

export {
    toggleFlag
}