const loadDetailView = (projectId, buildJobId) => {
    const projects = require('../projects');

    projectId = projectId || $('#js_projectIds').data('project-id');
    buildJobId = buildJobId || $('#js_projectIds').data('latest-buildjob-id');
    const groupSlug = $('#js_projectIds').data('group-slug');

    if ( !projectId || !buildJobId) {
        return;
    }

    $.get(`/${groupSlug}/projects/${projectId}/build_jobs/${buildJobId}/details`)
    .done(function(html) {
        $('#build-content').html(html);
        $('[data-toggle="tooltip"]').tooltip();

        projects.updateBuildjobs();
    });
};

export {
    loadDetailView
};
