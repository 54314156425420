// Custom DataTable configuration and initialization for different tables.
//
const sharedConfig = {
  // custom wrapper classes for info & filter & pagination, hides length information
  // https://datatables.net/reference/option/dom
  dom: '<"row"<"col"l><"col text-right"f>> r<"table-responsive"t><"row"<"col"i><"col text-right"p>>',
  language: {
    search: '<i class="fa fa-search" aria-hidden="true"></i> _INPUT_',
    searchPlaceholder: 'Search ...',
    paginate: {
      previous: '<i class="fa fa-chevron-left" aria-hidden="true"></i><span class="sr-only">Previous</span>',
      next: '<i class="fa fa-chevron-right" aria-hidden="true"></i><span class="sr-only">Next</span>'
    }
  },
  stateSave: true
};

const initUsersTable = () => {
  if (!$('#js_usersDataTable').length) {
    return;
  }

  $('#js_usersDataTable')
    .on('draw.dt', function () {
      // Re init tooltable
      $('[data-toggle="tooltip"]').tooltip();
    }).DataTable(
      $.extend({}, sharedConfig, {
        aaSorting: [[0, 'asc']],
        columns: [
          { data: 'id' },
          { data: 'email' },
          { data: 'username' },
          { data: 'options' }
        ],
        aoColumnDefs: [
          { "bSortable": false, "aTargets": [3] }
        ]
      })
    );
};

const initProjectsTable = () => {
  if (!$('#js_projectsDataTable').length) {
    return;
  }

  $('#js_projectsDataTable').on('draw.dt', function () {
    $('.js_project_show').click(function () {
      window.location = $(this).data("link");
    });
  }).DataTable(
    $.extend({}, sharedConfig, {
      ordering: false
    })
  );
};

const initGroupsTable = () => {
  if (!$('#js_groupsDataTable').length) {
    return;
  }

  $('#js_groupsDataTable').DataTable(
    $.extend({}, sharedConfig, {
      ordering: false
    })
  );
};

const initBuildFilesTable = () => {
  $('#js_buildFilesDataTable').DataTable(
    $.extend({}, sharedConfig, {
      aaSorting: [[1, 'desc']]
      // Do not define columns here since data-order attribute will not work, @see doku:
      // Basically columns.data causes DataTables to not auto-detect the HTML5
      // parameters since the data is defined with columns.data.
    })
  );
};

const initBuildListTable = () => {
  if (!$('#js_buildListDataTable').length) {
    return;
  }

  const projects_detailView = require('../javascripts/projects/detailsView');
  const projects_buildLogs = require('../javascripts/projects/buildLogs');

  sharedConfig.language.emptyTable = 'No builds yet';

  $('#js_buildListDataTable')
    .on('draw.dt', function () {
      // Re init tooltip
      $('[data-toggle="tooltip"]').tooltip();

      // Mark current build job as 'active'.
      const buildJobId = $('#js_projectIds').data('latest-buildjob-id');
      const currentBuildJobEntry = $(this).find('tbody tr .js_btn_details[data-id="' + buildJobId + '"]');
      currentBuildJobEntry.closest('tr').addClass('active');

      // Change state 'active' and update data helpers on click.
      $(this).find('tbody tr .js_btn_details').on('click', function (e) {
        $(this).closest('tr').addClass('active').siblings().removeClass('active');
        $('#js_projectIds').data('latest-buildjob-id', $(this).data('id'))
        projects_detailView.loadDetailView(undefined, $(this).data('id'));
      });

      $(this).find('tbody tr .js_build_status_icon').on('click', function (e) {
        const statusRef = $(e.currentTarget).data('log-level');
        const buildjobId = $(e.currentTarget).data('buildjob-id');
        const projectId = $('#js_projectIds').data('project-id');
        const groupSlug = $('#js_projectIds').data('group-slug');
        $.get(`/${groupSlug}/projects/${projectId}/build_jobs/${buildjobId}/logs.js`, [{ name: "ref", value: statusRef }]);
      });
    })
    .on('xhr.dt', function (e, settings, json, xhr) {
      const reloadTimeout = (json.data[0] && json.data[0].is_building === 'true') ? 5 : 300; // seconds
      // Note: clearInterval / setInterval not needed since the reloading
      // via AJAX re-checks if project is currently building.
      setTimeout(function () {
        $('#js_buildListDataTable').DataTable().ajax.reload();
        projects_detailView.loadDetailView();
        projects_buildLogs.updateLogs();
      }, reloadTimeout * 1000);
    }).DataTable($.extend({}, sharedConfig, {
      aaSorting: [[0, 'desc']],
      bSort: false,
      serverSide: true,
      retrieve: true,
      responsive: true,
      autoWidth: false,
      ajax: {
        url: $('#js_buildListDataTable').data('source')
      },
      columns: [
        { data: "version" },
        { data: "branch" },
        { data: "options" }
      ]
    })
  );
};

export {
  initUsersTable,
  initProjectsTable,
  initGroupsTable,
  initBuildFilesTable,
  initBuildListTable
};