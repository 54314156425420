const setActive = (settingId, settingsValue, settingsType) => {
    let groupSlug = $('#js_projectIds').data('group-slug');
    const url  = `/${groupSlug}/settings/${settingId}`;

    // $.post is not sufficient, since Rails needs to know the dataType.
    // This is necessary to process JS correctly, avoiding a request "processing as */*"
    // instead of a correct "processing as JS".
    $.ajax({
        type: 'PUT',
        url: url,
        data: { settings_value: settingsValue, settings_type: settingsType },
        dataType: 'script'
    });
};

export {
    setActive
}