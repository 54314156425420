// Toggles the side menu in framed project preview.
//
const toggleSideMenu = () => {
    const toggleBtn = $('.sidebar-toggle');
    const sidebar = $('.sidebar');

    toggleBtn.on('click', function () {
        toggleBtn.toggleClass('is-closed');
        sidebar.toggleClass('is-closed');

        checkFullWidth();
    });
};

// Updates the given value according to the dropdown.
//
const setPreviewFrame = () => {
    const frameSelector = $('#js_frame_preview');

    frameSelector.change(function() {
        const option = frameSelector.find('option:selected');
        const frameHeight = option.data('height');
        const frameWidth = option.data('width');

        const size = calculateFrameSize(frameWidth, frameHeight)
        updateFrame(size.width, size.height);
    }).change();
};


export {
    toggleSideMenu,
    setPreviewFrame
};


// Calculates the frame width and height
//
const calculateFrameSize = (widthScale, heightScale) => {
    const sizes = getSizes();
    const frameHeight = (sizes.screenWidth / widthScale) * heightScale;

    if (frameHeight <= sizes.screenHeight) {
        return { width: sizes.screenWidth, height: frameHeight };
    }

    const frameWidth = ((sizes.screenHeight) / heightScale) * widthScale;

    return { width: frameWidth, height: sizes.screenHeight };
};

// Update the frame size in framed project preview.
//
const updateFrame = (frameWidth, frameHeight) => {
    const container = $('.frame-container');
    container.width(frameWidth);
    container.height(frameHeight);
    checkFullWidth(frameWidth);

    // Reload game in iframe.
    $('#js_iframe').attr('src', (index, attr) => attr);
};

// Checks if the container fills the available viewport in order to add necessary classes.
//
const checkFullWidth = (frameWidth) => {
    const sizes = getSizes();
    const containerWidth = frameWidth ? frameWidth : sizes.container.width();
    const screenWidth = window.innerWidth - (sizes.sidebarWidth * 2);

    // Align the container in the middle if the container is wider than the sidebar * 2
    let middleFromRight = (screenWidth + sizes.sidebarWidth) - containerWidth;
    if (middleFromRight <= 7 ) middleFromRight = 0;

    if (!sizes.sidebar.hasClass('is-closed') && containerWidth > screenWidth) {
        sizes.container.addClass('fullFrame');
        sizes.container.css('right', (middleFromRight / 2));
    } else {
        sizes.container.removeClass('fullFrame');
        sizes.container.css('right', 0);
    }
};

// Gets all the necessary sizes.
//
const getSizes = () => {
    const container = $('.frame-container');
    const sidebar = $('.sidebar');

    // There is a frame border of 7px width. We therefore need to remove
    // 14px (7px left + 7px right) from the width and 14px (7px top + 7px bottom)
    // from the height to fit the viewport.
    // When changing the pixel size here, please also adapt frame.scss accordingly.
    const sidebarWidth = sidebar.outerWidth() + 14;
    const screenHeight = window.innerHeight - 14;
    const screenWidth = window.innerWidth - sidebarWidth;

    return {
        container: container,
        sidebarWidth: sidebarWidth,
        screenHeight: screenHeight,
        screenWidth: screenWidth,
        sidebar: sidebar
    }
};