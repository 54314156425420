// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

import 'bootstrap/dist/js/bootstrap';
import 'datatables/media/js/jquery.dataTables';
import 'select2/dist/js/select2';
import Swiper from 'swiper';
import { Scrollbar, Autoplay } from 'swiper/modules';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

const dataTables = require('../javascripts/dataTables');
const header = require('../javascripts/header');
const projects = require('../javascripts/projects');
const frame = require('../javascripts/frame');
const general = require('../javascripts/general');
const projects_detailView = require('../javascripts/projects/detailsView');
const projects_buildLogs = require('../javascripts/projects/buildLogs');
const projects_loadingScreen = require('../javascripts/projects/loading_screen_configuration');
const users = require('../javascripts/users');


document.addEventListener("turbolinks:load", function () {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover({html:true});

    dataTables.initUsersTable();
    dataTables.initProjectsTable();
    dataTables.initGroupsTable();
    dataTables.initBuildListTable();
    header.highlightCurrentMenu();
    header.sliderNavigation();
    projects.updateTabUrl();
    projects.updateSettingsForm();
    projects.loadScenes();
    projects.updateSettings();
    projects.prefillProject();
    projects.refreshBranchData();
    frame.toggleSideMenu();
    frame.setPreviewFrame();
    general.showFileName();
    general.closeCookieBanner();
    general.closeNewsletterModal();
    general.initTrailer();
    general.initSwiper(Swiper, Scrollbar, Autoplay);
    general.showTutorialModal();
    general.highlightSelectedFaq(),
    projects_detailView.loadDetailView();
    projects_loadingScreen.toggleLoadingScreenOptions();
    projects.toggleExperimentalBuild();
    users.togglePassword();
});

$(document).ready(function(){
    general.showDisclaimer();
});

// Prevents duplication of dataTable wrappers (e.g. pagination, search, etc)
// when using browser back instead of navigation.
//
$(document).on('turbolinks:before-cache', function () {
    let dataTable =  $($.fn.dataTable.tables(true)).DataTable();
    if (dataTable != null)
        dataTable.destroy();
    dataTable = null;
});

// For usage in js.erb files.
window.playcanvas = {
    dataTables,
    projects_buildLogs
};
