const highlightCurrentMenu = () => {
    // Fetches the current page and highlights the according menu link.
    $('#js_navbarMain li').each(function () {
        const path = window.location.pathname;
        const href = $(this).find('a').attr('href');

        if (path.match(href)) {
            $(this).addClass('active').siblings().removeClass('active');
        }
    });
};

const sliderNavigation = () => {
    const nav = $('.nav-pills-underline');
    if (!nav.length) {
        return;
    }

    const initialElement = nav.find('.nav-link.active');
    const initialWidth = initialElement.width();
    const initialPosition = initialElement.position();
    const top = initialPosition.top + initialElement.outerHeight() - 4; // 4px == slider height

    $('.slider').css({'left': initialPosition.left, 'top': top, 'width': initialWidth});

    $('.nav-pills-underline .nav-link').click(function () {
        let position = $(this).position();
        let width = $(this).width();
        let top = position.top + $(this).outerHeight() - 4; // 4px == slider height

        $('.slider').css({'left': position.left, 'top': top, 'width': width});
    });
};

export {
    highlightCurrentMenu,
    sliderNavigation
};