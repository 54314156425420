let baseSelector;

const init = (statusRef) => {
  baseSelector = $('#build-logs-modal');

  if (!statusRef) statusRef = 'info';

  const statusClass = ".js_" + statusRef;
  filterLogs(statusClass);
  $(`#js_filter_logs_form option[value='${ statusClass }'`).attr('selected', '');

  baseSelector.find('#js_filter_logs_form').change(function () {
    const selectedValue = $(this).find(':selected').val();
    filterLogs(selectedValue);
  });
};

const destroy = () => {
  baseSelector = undefined;
};

const filterLogs = (selectedValue) => {
  const levelHierarchy = ['.js_debug', '.js_info', '.js_warn', '.js_error'];
  const selectedHierarchyIndex = levelHierarchy.indexOf(selectedValue);
  const selectedHierarchy = levelHierarchy.slice(selectedHierarchyIndex).join();

  $(selectedHierarchy).show();
  baseSelector.find('.list-group-item').not(selectedHierarchy).hide();
};

const updateLogs = () => {
  const projectId = $('#js_projectIds').data('project-id');
  const buildJobId = $('#js_projectIds').data('latest-buildjob-id');
  const groupSlug = $('#js_projectIds').data('group-slug');

  if (!baseSelector) {
    return;
  }

  const element = baseSelector.find('.modal-body > ul');
  if (element.length === 0) {
    return;
  }

  $.get(`/${groupSlug}/projects/${projectId}/build/${buildJobId}/logs`)
    .done(function (html) {
      element.html(html);
      const selectedValue = baseSelector.find('#js_filter_logs_form :selected').val();
      filterLogs(selectedValue);
    });
};

export {
  init,
  destroy,
  filterLogs,
  updateLogs
}