// Show file name after file select.
//
const showFileName = () => {
    $('.custom-file-input').change(function(){
        if (!(this.files && this.files[0])) {
            return;
        }

        const fileName = this.files[0].name;
        $(this).prev('.custom-file-label').html(fileName);
    });
};

// Close the cookie banner.
//
const closeCookieBanner = () => {
    $('#js_closeBanner').on('click', function(e) {
        e.preventDefault();
        $(this).parents('#js_cookieBanner').addClass('d-none');
    });
};

// Shows the tutorial modal.
//
const showTutorialModal = () => {
    $('#first-login-modal').modal();
};

// Close the Newsletter banner.
//
const closeNewsletterModal = () => {
    let newsletterSelector = $('#js_newsletter');

    newsletterSelector.find('a').on('click', function(e) {
        e.preventDefault();
        newsletterSelector.addClass('d-none');
    });
};

// Show Redox Interactive disclaimer.
//
const showDisclaimer = () => {
    // Don't show in frame preview, since the game itself is already outputting the same disclaimer.
    if ($('#js_iframe').length) {
        return;
    }

    console.log(
        '%c 🕹 by Redox Interactive',
        'color: #21201f; font-size: 14px; font-family:system-ui; background: #f4f4f4; padding: 6px 12px 6px 6px;'
    );
};

// Improves youtube video quality by using a big width & height combination
// and then by reducing the size via a dynamically added CSS class after initial video loading.
//
const initTrailer = () => {
    const trailer = $('#trailer');

    setTimeout(() => {
        trailer.addClass('trailer');
    }, 100);
};

// Initializes the swiper for the landing page
//
const initSwiper = (Swiper, Scrollbar, Autoplay) => {
    const swiper = new Swiper('.swiper', {
        loop: true,
        modules: [Scrollbar, Autoplay],
        speed: 3000,
        autoplay: {
            delay: 6000,
            disableOnInteraction: true,
        },
        scrollbar: {
            el: '.swiper-scrollbar',
            draggable: true
        },
    });
};

const highlightSelectedFaq = () => {
    $('#faqAccordion').on('show.bs.collapse', function (e) {
        const collapsedCard = $(e.target).parent('.card');
        collapsedCard.addClass('border-primary');
    });

    $('#faqAccordion').on('hide.bs.collapse', function (e) {
        const collapsedCard = $(e.target).parent('.card');
        collapsedCard.removeClass('border-primary');
    });
};

export {
    showFileName,
    closeCookieBanner,
    showTutorialModal,
    closeNewsletterModal,
    initTrailer,
    initSwiper,
    showDisclaimer,
    highlightSelectedFaq
};