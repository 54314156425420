// Toggles the password visibility
//
const togglePassword = () => {
    const toggleElement = $('.js_toggle-password');

    if (!toggleElement.length) {
        return;
    }

    toggleElement.click(function () {
        $(this).toggleClass('fa-eye fa-eye-slash');
        const input = $($(this).data('toggle'));

        if (input.attr('type') === 'password') {
            input.attr('type', 'text');
        } else {
            input.attr('type', 'password');
        }
    });
};

export { togglePassword };