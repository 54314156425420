// Toggles different options for the loading screen configuration.
const toggleLoadingScreenOptions = () => {
    $('#loading_screen_configuration_bg_mode').change(function() {
        const selectedValue = $(this).find(':selected').val();

        if (selectedValue === 'BG_IMAGE' && $('#js_bgImageOptions').hasClass('d-none')) {
            $('#js_bgImageOptions').removeClass('d-none');
        } else {
            $('#js_bgImageOptions').addClass('d-none');
        }

        if (selectedValue === 'BG_GRADIENT' && $('#js_gradientOptions').hasClass('d-none')) {
            $('#js_gradientOptions').removeClass('d-none');
        } else {
            $('#js_gradientOptions').addClass('d-none');
        }

        if (selectedValue === 'BG_REPEAT' && $('#js_bgRepeatImageOptions').hasClass('d-none')) {
            $('#js_bgRepeatImageOptions').removeClass('d-none');
        } else {
            $('#js_bgRepeatImageOptions').addClass('d-none');
        }
    });

    $('#js_toggleGlow').change(function() {
        $('#js_glowOptions').toggleClass('d-none');
    });

    $('#js_toggleLogo').change(function() {
        $('#js_logoOptions').toggleClass('d-none');
    });

    $('#js_togglePbUseImages').change(function() {
        $('#js_pbImageOptions').toggleClass('d-none');
        $('#js_pbModeOptions').toggleClass('d-none');
        $('#js_barModeOptions').toggleClass('d-none');
        $('#js_pbBorderImageOptions, #js_pbBorderCssOptions').toggleClass('d-none');
    });

    $('#js_toggleRunner').change(function() {
        $('#js_runnerOptions').toggleClass('d-none');
    });

    $('#js_toggleRunnerGloss').change(function() {
        $('#js_runnerGlossOptions').toggleClass('d-none');
    });

    $('#loading_screen_configuration_pb_mode').change(function() {
        $('#js_pbModeColor').toggleClass('d-none');
        $('#js_pbModeGradient').toggleClass('d-none');
    });

    $('#js_togglePbBorderOptions').change(function() {
        $('#js_pbBorderOptions').toggleClass('d-none');
    });

    $('#loading_screen_configuration_bar_mode').change(function() {
        $('#js_barModeColor').toggleClass('d-none');
        $('#js_barModeGradient').toggleClass('d-none');
    });
};

export {
    toggleLoadingScreenOptions
};
