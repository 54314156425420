let groupSlug;

const registerBranchFilter = () => {
    groupSlug = $('#js_projectIds').data('group-slug');
    $('#js_filter_scenes').change(function(){
        const branchId = $(this).find(':selected').val();
        filterScenes(branchId);
    });
};

const registerMainSceneButton = () => {
    $('.js_btn_scenes_sort').click(function() {
        let url = $(this).data('url');
        return makeRequest(url);
    });
};

const registerSetActiveButton = () => {
    $('.js_btn_scenes_active').change(function() {
        let url = $(this).data('url');
        return makeRequest(url);
    });
};

const filterScenes = (branchId) => {
    const url = `/${groupSlug}/${branchId}/scenes`;
    return makeRequest(url);
};

const makeRequest = (url) => {
    const activePromise = $.get(url);
    activePromise.done(function(html) {
        $('#scenes-content').html(html);

        registerMainSceneButton();
        registerSetActiveButton();
    });
    return activePromise;
};

export {
    registerBranchFilter,
    registerMainSceneButton,
    registerSetActiveButton,
    filterScenes
}