// Update URL to match selected tab (user details)
//
const updateTabUrl = () => {
    if (!$('#js_projectNav .nav-link').length) {
        return;
    }

    $('#js_projectNav .nav-link').on('click', function () {
        window.history.pushState($(this).attr('aria-controls'), 'tab', '?tab=' + $(this).attr('aria-controls'));
    });
};

const updateSettingsForm = () => {
    $('#setting_value_type').change(function(){
        const selectedValue = $(this).find(':selected').val();

        const inputInteger = `
            <label for="setting_value">Value</label>
            <input step="1" class="form-control" type="number" value="0" name="setting[value]" id="setting_value">`;

        const inputFloat = `
            <label for="setting_value">Value</label>
            <input step="0.1" class="form-control" type="number" value="0.0" name="setting[value]" id="setting_value">`;

        const inputString = `
            <label for="setting_value">Value</label>
            <input placeholder="Text" class="form-control" type="text" maxlength="255" autocomplete="off" name="setting[value]" id="setting_value">`;

        const toggleBoolean = `
            <div class="custom-control custom-switch">
                <input name="setting[value]" type="hidden" value="false">
                <input class="custom-control-input" type="checkbox" value="true" name="setting[value]" id="setting_value">
                <label class="custom-control-label" for="setting_value">Active?</label>
            </div>`;

        switch (selectedValue){
            case 'boolean':
                $('#js_switchableFormInput').html(toggleBoolean);
                break;

            case 'integer':
                $('#js_switchableFormInput').html(inputInteger);
                break;

            case 'float':
                $('#js_switchableFormInput').html(inputFloat);
                break;

            default: // 'string'
                $('#js_switchableFormInput').html(inputString);
        }
    });
};

const loadScenes = () => {
    const projects_sceneService = require('../javascripts/projects/sceneService');
    projects_sceneService.registerBranchFilter();

    const branchId = $('#js_filter_scenes').find(':selected').val();

    if (branchId) {
        projects_sceneService.filterScenes(branchId);
    }
};

const updateSettings = () => {
    const projects_settingsService = require('../javascripts/projects/settingsService');

    $('.js_btn_settings_active').change(function() {
        let settingId = $(this).data('setting-id');
        let settingsValue = $(this).data('setting-value');
        let settingsType = $(this).data('setting-type');

        projects_settingsService.setActive(settingId, settingsValue, settingsType);
    });

    var typingTimer;
    var doneTypingInterval = 1000;

    $('.js_settings_input').on('input keyup propertychange change paste', function () {
        let settingsValue;
        let settingId = $(this).data('setting-id');
        let settingsType = $(this).data('setting-type');

        clearTimeout(typingTimer);
        settingsValue = $(this).val();
        typingTimer = setTimeout(() => doneTyping(settingId, settingsValue, settingsType), doneTypingInterval);
    });

    function doneTyping (settingId, settingsValue, settingsType) {
        projects_settingsService.setActive(settingId, settingsValue, settingsType);
    };
};

const updateBuildjobs = () => {
    const projects_buildJobService = require('../javascripts/projects/buildJobService');
    const projects_detailsView = require('./projects/detailsView');

    $('.js_btn_buildjob_keep, .js_btn_buildjob_public').change(function() {
        const flagName = $(this).data('build-job-flag');
        const buildJobId = $(this).data('build-job-id');
        const projectId = $('#js_projectIds').data('project-id');

        projects_buildJobService.toggleFlag(flagName, projectId, buildJobId)
        .catch(() => {
            // Reload view if toggle failed
            projects_detailsView.loadDetailView(projectId, buildJobId);
        });
    });
};

const prefillProject = () => {
    let buttons = $('.js_fetchProjects');

    buttons.each((idx, btn) => {
        $(btn).on('click', function () {
            const projectId = $(this).data('id');
            const projectName = $(this).data('name');
            const projectDescription = $(this).data('description');
            const projectThumbnail = $(this).data('thumbnail');
            const form = $('#js_projectCloneForm');

            form.find('#project_project_id').val(projectId);
            form.find('#project_name').val(projectName);
            form.find('#project_description').val(projectDescription);
            form.find('#thumbnail_img_aws').val(projectThumbnail);
            form.find(':submit').click();
        });
    });
};

// Shows a loading spinner while refreshing the branches data.
//
const refreshBranchData = () => {
    $('#js_refreshBranchData').on('click', function(e) {
        const icon = $(this).find('i');
        // Prevent to trigger the update twice.
        if (icon.hasClass('fa-spin')) e.preventDefault();
        // Show loading spinner.
        icon.removeClass().addClass('fas fa-circle-notch fa-spin ml-2');
    });
};

// Shows or hides the dropdown for selecting an experimental build / engine version.
//
const toggleExperimentalBuild = () => {
    $('#js_experimentalBuild').on('click', function(e) {
        $('#js_experimentalDropdown').toggleClass('d-none');
    });
};

export {
    updateTabUrl,
    updateSettingsForm,
    loadScenes,
    updateSettings,
    updateBuildjobs,
    prefillProject,
    refreshBranchData,
    toggleExperimentalBuild
};
